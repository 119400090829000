import { useEffect, useState } from "react";
import Loading from "../Util/Loading";
import Pagination from "../Util/Pagination";
import "../../Styles/users.scss";
import WaveLoading from "../Util/WaveLoading";
import Group from "./Group";

export default function Groups(props) {
  const jwt = require("jsonwebtoken");
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [value, setValue] = useState(null);
  const [error, setError] = useState(null);

  // const token = localStorage.getItem("gdfhgfhtkn");
  // var decoded = jwt.decode(token);
  // const level = decoded.Level?.replace(" ", "");
  // const levelname = decoded.LevelName;

  useEffect(() => {
    let url = value
      ? `/api/farmergroups/name/${value}/${(currentPage - 1) * 12}`
      : `/api/farmergroups/paginated/${(currentPage - 1) * 12}`;
    setLoading(true);
    fetch(url)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else throw Error("");
      })
      .then((data) => {
        setLoading(false);
        setData(data);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, [currentPage, props.filter, refresh]);

  return (
    <div className="users">
      <div className="list">
        <div className="dtitle">
          <h3>Farmer Groups</h3>
          <div className="search">
            {showSearch && (
              <input
                // className="query"
                onChange={(e) => {
                  setError("");
                  setValue(e.target.value);
                }}
                placeholder="Search by Name"
                type="text"
              />
            )}
            <i
              className="fa fa-search "
              onClick={() => {
                value ? setRefresh(!refresh) : setShowSearch(!showSearch);
              }}
            ></i>
          </div>
        </div>
        <div className="head">
          <h4>No</h4>
          <h4>Name</h4>
          <h4>Type</h4>
          <h4>Date Created</h4>
        </div>
        <div className="content">
          {!loading &&
            data &&
            data?.result?.map((item, index) => {
              return (
                <Group
                  refresh={refresh}
                  setRefresh={setRefresh}
                  key={index}
                  page={currentPage}
                  index={index}
                  item={item}
                  url="mobile"
                />
              );
            })}
        </div>

        {loading && <WaveLoading />}
        {data?.total && (
            <Pagination
              totalItems={data?.total}
              onPageChange={(v) => {
                setCurrentPage(v);
              }}
              currentPage={currentPage}
            />
          )}
      </div>
    </div>
  );
}
