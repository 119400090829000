import { useRef, useState } from "react";
import Button from "../Util/Button";
import Loading from "../Util/Loading";
import UserInput from "../Users/UserInput";
import UserSelect from "../Users/UserSelect";
import GroupInput from "./GroupInput";

export default function NewGroup(props) {
  const [loading, setLoading] = useState(false);
  const [searchFarmers, setsearchFarmers] = useState(null);
  const [memberID, setMemberID] = useState(null);
  const [error, setError] = useState("");
  const gname = useRef();
  const type = useRef();
  const valuechain = useRef();
  const password = useRef();
  const cpassword = useRef();

  function titleCase(str) {
    var splitStr = str.toLowerCase().split(" ");
    for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(" ");
  }

  const createGroup = () => {
    const body = {
      FarmerID: memberID,
      Name: titleCase(gname.current.getValue()),
      Type: type.current.getValue(),
      // Email: email.current.getValue().toLowerCase().trim(),
      ValueChain: valuechain.current.getValue(),
      Password: password.current.getValue(),
      CPassword: cpassword.current.getValue(),
    };
    setLoading(true);
    setError("");

    const validateForm = () => {
      let result = true;
      if (
        !validatePassword(body.Password) ||
        !validatePassword(cpassword.current.getValue())
      ) {
        result = false;
        setError("Password must be at least 6 characters!");
        setLoading(false);
        return result;
      }
      if (body.Password !== cpassword.current.getValue()) {
        result = false;
        setError("Passwords do not match!!!");
        setLoading(false);
        return result;
      }
      if (!body.FarmerID) {
        result = false;
        setError("First group member's ID is reqired!");
        setLoading(false);
        return result;
      }
      if (!body.Name) {
        result = false;
        setError("Name is reqired!");
        setLoading(false);
        return result;
      }
      return result;
    };

    if (validateForm()) {
      fetch(`/api/farmergroups`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(body),
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else throw Error("Creation Failed!!!");
        })
        .then((data) => {
          setLoading(false);
          if (data.success) {
            setError(data.success);
            setTimeout(() => {
              window.location.href = "/mobile/Users";
            }, 1000);
          } else {
            setError(data.error);
          }
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  };

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };
  const validatePassword = (password) => {
    return password.length >= 6;
  };

  function searchFarmer(gname, q) {
    fetch(`/api/farmerdetails/seachbynid/${q}`)
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else throw Error();
      })
      .then((data) => {
        setsearchFarmers(data);
      })
      .catch((e) => {});
  }

  const List = (params) => {
    return (
      <div
        onClick={() => {
          params.setMemberID(params.item.NationalID);
        }}
        className="s_list"
      >
        <p>
          {params.item.Name} - {params.item.Phone}
        </p>
      </div>
    );
  };

  return (
    <div className="users">
      <div className="list">
        <h3>New Farmer Group</h3>
        <hr />
        <div className="new">
          <h6>{error}</h6>
          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
            autoComplete="off"
          >
            <input
              autoComplete="false"
              name="hidden"
              type="text"
              style={{ display: "none" }}
            />
            <div className="div2equal">
              <UserInput ref={gname} type="text" label="Group's Name *" />
              <UserSelect ref={type} label="Type *" data={["CIG", "SACCO"]} />
            </div>

            <div className="div2equal">
              <UserInput ref={valuechain} type="text" label="ValueChain *" />
              <GroupInput
                type="number"
                handleChange={(e) => {
                  setMemberID(null);
                  searchFarmer(props.gname, e);
                }}
                value={memberID && memberID}
                label="Search ID Number"
                hint="Type to search"
              />
              <div className="search_list">
                {
                  !memberID && searchFarmers?.map((item, i) => {
                    return (
                      <List key={i} item={item} setMemberID={setMemberID} />
                    );
                  })
                }
              </div>
            </div>

            <div className="div2equal">
              <UserInput ref={password} type="password" label="Password *" />
              <UserInput
                ref={cpassword}
                type="password"
                label="Confirm Password *"
              />
            </div>
            <Button handleClick={createGroup} value="Submit" />
          </form>
          {loading && <Loading />}
        </div>
      </div>
    </div>
  );
}
