import "../../Styles/data.scss";
import { useState } from "react";
import { useEffect } from "react";
import Pagination from "../Util/Pagination";
import WaveLoading from "../Util/WaveLoading";
import Button from "../Util/Button";
import GroupInput from "../Groups/GroupInput";
import { isMobile } from "react-device-detect";
import VCMap from "./VCMap";
import VCStats from "./VCStats";

export default function VCData(props) {
  const [data, setData] = useState(null);
  const [mapData, setMapData] = useState(null);
  const [head, setHead] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState(0);
  const [paginatedMap, setPaginatedMap] = useState(true);
  const [isloading, setIsLoading] = useState(false);
  const [showing, setShowing] = useState(null);
  const [toggleAddMember, setToggleAddMember] = useState(false);
  const [selected, setSelected] = useState(null);
  const [editing, setEditing] = useState(null);
  // const [refresh, setRefresh] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  // const [value, setValue] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    fetch(`${props.url}/${(currentPage - 1) * 12}`)
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        setIsLoading(false);
        if (data?.data?.length > 0) {
          const cols = Object.keys(data.data[0]);
          let d = [];
          cols.forEach((item) => {
            if (
              item.toLowerCase() !== "nationalid" &&
              item.toLowerCase() !== "farmingtype" &&
              item.toLowerCase() !== "id" &&
              item.toLowerCase() !== "createdat" &&
              item.toLowerCase() !== "updatedat" &&
              item.toLowerCase() !== "county" &&
              item.toLowerCase() !== "harvestmonth" &&
              item.toLowerCase() !== "harvestyear" &&
              item.toLowerCase() !== "latitude" &&
              item.toLowerCase() !== "longitude" &&
              item.toLowerCase() !== "produceid" &&
              item.toLowerCase() !== "season" &&
              item.toLowerCase() !== "farmerid" &&
              item.toLowerCase() !== "valuechain" &&
              item.toLowerCase() !== "valuechainID" &&
              item.toLowerCase() !== "subcounty" &&
              item.toLowerCase() !== "ward" &&
              item.toLowerCase() !== "village" &&
              item.toLowerCase() !== "landsize" &&
              item.toLowerCase() !== "periodend" &&
              item.toLowerCase() !== "growingmethod" &&
              item.toLowerCase() !== "village" &&
              item.toLowerCase() !== "valuechainname" &&
              item.toLowerCase() !== "noofgreenhouses" &&
              item.toLowerCase() !== "greenhousesize" &&
              item.toLowerCase() !== "village" &&
              item.toLowerCase() !== "valuechainname" &&
              item.toLowerCase() !== "produceamount"
            ) {
              d.push(item);
            }
          });
          if (isMobile) {
            setCount(2);
          } else {
            let c = d.length > 5 ? 5 : d.length;
            setCount(c);
          }
          setHead(d);
          setData(data);
          paginatedMap && setMapData(data);
        } else if (data?.result?.length > 0) {
          const cols = Object.keys(data.result[0]);
          let d = [];
          cols.forEach((item) => {
            if (
              item.toLowerCase() !== "nationalid" &&
              item.toLowerCase() !== "farmingtype" &&
              item.toLowerCase() !== "id"
            ) {
              d.push(item);
            }
          });
          if (isMobile) {
            setCount(2);
          } else {
            let c = d.length > 5 ? 5 : d.length;
            setCount(c);
          }
          setHead(d);
          setData(data);
        }
      })
      .catch((e) => {
        setIsLoading(false);
      });
  }, [currentPage, props.refresh, paginatedMap]);

  useEffect(() => {
    if (!paginatedMap) {
      setIsLoading(true);
      fetch(`/api/farmeraddress/completevcdata/${props.title}`)
        .then((res) => {
          if (res.ok) return res.json();
          else throw Error("");
        })
        .then((data) => {
          setMapData(data);
          setIsLoading(false);
        })
        .catch((e) => {
          setIsLoading(false);
        });
    }
  }, [currentPage, props.refresh, paginatedMap]);

  console.log(props.title.toLowerCase());

  return (
    <div className="vdata">
      <div className="vtitle">
        <h3>{`Valuechain: ${props.title.toLowerCase()}`}</h3>
        <div className="search">
          {showSearch && (
            <input
              // className="query"
              onChange={(e) => {
                setError("");
                props.setValue(e.target.value);
              }}
              type="text"
            />
          )}
          <i
            className="fa fa-search "
            onClick={() => {
              props.value
                ? props.setRefresh(!props.refresh)
                : setShowSearch(!showSearch);
            }}
          ></i>
        </div>
      </div>
      <hr />
      <div className="titles">
        <h3>{`Stats for ${props.title}`}</h3>
      </div>
      <div className="vstats">
        <VCStats
          title={props.title
            .replace(" ", "")
            .replace("(", "")
            .replace(")", "")
            .replace("  & ", "")
            .replace(" ", "")}
          setRefresh={props.setRefresh}
          refresh={props.refresh}
          setValue={props.setValue}
          value={props.value}
        />
      </div>
      <hr />
      <div className="maptitles">
        <h3>{`Map for ${props.title}`}</h3>
        <i onClick={() => setPaginatedMap(!paginatedMap)}>
          {paginatedMap
            ? `Show all ${props.title} Farmers`
            : `Show listed ${props.title} Farmers`}
        </i>
      </div>
      <div className="vmap">
        {data && data?.data?.length > 0 && (
          <VCMap
            url={props.dataURL}
            data={mapData?.data}
            title={props.title}
            setRefresh={props.setRefresh}
            refresh={props.refresh}
            setValue={props.setValue}
            value={props.value}
          />
        )}
      </div>
      <hr />
      <div className="titles">
        <h3>{`Farmers of ${props.title}`}</h3>
      </div>
      <div className="list">
        <div
          style={{
            gridTemplateColumns: `repeat(${head ? count : 0},1fr)`,
          }}
          className="head"
        >
          {head &&
            head.length > 0 &&
            head.map((item, i) => {
              if (i < count) {
                return <h4 key={i}>{item}</h4>;
              }
            })}
        </div>
        {data && data?.data?.length > 0
          ? data?.data?.map((item, i) => {
              return (
                <Item
                  setShowing={setShowing}
                  key={i}
                  index={i}
                  data={item}
                  head={head}
                  count={count}
                />
              );
            })
          : data?.result?.length > 0 &&
            data?.result?.map((item, i) => {
              return (
                <Item
                  setShowing={setShowing}
                  key={i}
                  index={i}
                  data={item}
                  head={head}
                  count={count}
                />
              );
            })}
        <div className="btns">
          {props.title.includes("Members") && (
            <i
              className="fa fa-plus newMember"
              title="Add new member"
              onClick={() => {
                setToggleAddMember(!toggleAddMember);
                setShowing(null);
              }}
            ></i>
          )}

          {data?.total && (
            <Pagination
              totalItems={data?.total}
              onPageChange={(v) => {
                setCurrentPage(v);
              }}
              currentPage={currentPage}
            />
          )}
        </div>
        {showing !== null && (
          <Popup
            showing={showing}
            editing={editing}
            setEditing={setEditing}
            setShowing={setShowing}
            data={data?.data}
            url={props.url}
          />
        )}
        {toggleAddMember && (
          <AddMember
            showing={showing}
            editing={editing}
            setEditing={setEditing}
            setToggleAddMember={setToggleAddMember}
            data={data?.data}
            url={props.url}
            gname={props.gname}
            gtype={props.gtype}
            setSelected={setSelected}
            selected={selected}
          />
        )}
      </div>
      {isloading && <WaveLoading />}
    </div>
  );
}

const Popup = (props) => {
  const [isLoading, setIsLoading] = useState(null);
  const [cols, setCols] = useState(null);
  const [cls, setCls] = useState(null);
  const [data, setData] = useState(null);
  const [index, setIndex] = useState(0);
  const [active, setActive] = useState("Basic");

  useEffect(() => {
    setIsLoading(true);
    setCols(null);
    setCls(null);
    setData(null);
    if (active === "Basic") {
      if (props.showing !== null) {
        const cols = Object.keys(props.data[props.showing]);
        let d = [];
        cols.forEach((item) => {
          if (item.toLowerCase() !== "geom") {
            d.push(item);
          }
        });
        setCols(d);
      }
      setIsLoading(false);
    } else {
      let d = "farmeraddress";
      switch (active) {
        case "Address":
          d = "farmeraddress";
          break;
        case "Farm":
          d = "farmerresources";
          break;
        case "Groups":
          d = "farmergroups";
          break;
        case "Value Chains":
          d = "farmervaluechains";
          break;
        default:
          setActive("Basic");
          break;
      }
      fetch(`/api/${d}/${props.data[props.showing].NationalID}`)
        .then((res) => {
          if (res.ok) return res.json();
          else throw Error("");
        })
        .then((data) => {
          setIsLoading(false);
          if (data.length > 0) {
            setData(data);
            const cols = Object.keys(data[0]);
            let d = [];
            cols.forEach((item) => {
              if (item.toLowerCase() !== "geom") {
                d.push(item);
              }
            });
            setCls(d);
          }
        })
        .catch((e) => {
          setIsLoading(false);
        });
    }
  }, [active]);

  const Bar = (params) => {
    return (
      <p
        onClick={() => {
          setActive(params.txt);
        }}
        className={active === params.txt ? "active" : ""}
      >
        {params.txt}
      </p>
    );
  };

  return (
    <div data-aos="fade-left" className="popup">
      <div className="container">
        <div className="dets">
          <h3>{props?.data[props.showing]?.Name}</h3>
          <i
            onClick={() => {
              props.setShowing(null);
            }}
            className="fa fa-times"
          ></i>
        </div>
        <div className="bar">
          <Bar txt="Basic" />
          <Bar txt="Address" />
          <Bar txt="Farm" />
          <Bar txt="Groups" />
          <Bar txt="Value Chains" />
        </div>
        <div className="content">
          {cols &&
            cols.map((item, i) => {
              return (
                <p key={i}>
                  <b>{item}</b> {props.data[props.showing][item]}
                </p>
              );
            })}
          {cls &&
            cls.map((item, i) => {
              return (
                <p key={i}>
                  <b>{item}</b> {data[index][item]}
                </p>
              );
            })}
          {isLoading && <WaveLoading />}
          <div className="tally">
            {data &&
              data.length > 1 &&
              data.map((item, i) => {
                return (
                  <p
                    className={i === index ? "active" : ""}
                    onClick={() => {
                      setIndex(i);
                    }}
                    key={i}
                  >
                    {i + 1}
                  </p>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

const AddMember = (props) => {
  const [searchFarmers, setsearchFarmers] = useState(null);
  const [success, setSuccess] = useState(null);
  const [isError, setIsError] = useState(null);

  const [body, setBody] = useState(null);

  useEffect(() => {
    props.selected &&
      setBody({
        FarmerID: props.selected.NationalID,
        Name: props.gname,
        Type: props.gtype,
      });
  }, [props.selected]);

  const List = (params) => {
    return (
      <div
        onClick={() => {
          props.setSelected(params.item);
        }}
        className="s_list"
      >
        <p>
          {params.item.Name} - {params.item.Phone}
        </p>
      </div>
    );
  };

  function searchFarmer(gname, q) {
    fetch(`/api/farmerdetails/seachbynid/${q}`)
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else throw Error();
      })
      .then((data) => {
        setsearchFarmers(data);
      })
      .catch((e) => {});
  }

  function addFarmerToGroup() {
    fetch(`/api/farmergroups`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else throw Error();
      })
      .then((data) => {
        setSuccess(data.success);
        setIsError(data.error);
      })
      .catch((e) => {});
  }

  return (
    <div data-aos="fade-left" className="popup">
      <div className="container">
        <div className="dets">
          <h3>{props?.data[props.showing]?.Name}</h3>
          <i
            onClick={() => {
              props.setToggleAddMember(false);
              props.setSelected(null);
            }}
            className="fa fa-times"
          ></i>
        </div>

        {props.selected ? (
          <div className="confirm">
            {success && <p className="success">{success}</p>}
            {isError && <p className="isError">{isError}</p>}
            <p>
              Confirm to add {props.selected.Name} to{" "}
              {props.gname.replaceAll("%20", " ")}?
            </p>
            <i
              className="newMember"
              onClick={() => {
                if (success || isError) {
                  props.setToggleAddMember(false);
                  setSuccess(null);
                  setIsError(null);
                } else {
                  addFarmerToGroup();
                }
              }}
            >
              Okay
            </i>
          </div>
        ) : (
          <div className="search">
            <p>Add a member to {props.gname.replaceAll("%20", " ")}</p>
            <GroupInput
              type="number"
              handleChange={(e) => {
                searchFarmer(props.gname, e);
              }}
              label="Search ID Number"
              hint="Type to search"
            />
            <div className="search_list">
              {searchFarmers?.map((item, i) => {
                return (
                  <List key={i} item={item} setSelected={props.setSelected} />
                );
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const Item = (props) => {
  const cl = props.index % 2 == 0 ? "white" : "#60606010";

  return (
    <div
      style={{
        gridTemplateColumns: `repeat(${props.head ? props.count : 0},1fr)`,
        backgroundColor: cl,
      }}
      className="row"
      onClick={() => {
        props.setShowing(props.index);
      }}
    >
      {props.head.map((item, i) => {
        if (i < props.count) {
          return <p key={i}>{props.data[item]}</p>;
        }
      })}
    </div>
  );
};
