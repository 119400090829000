import "../../Styles/data.scss";
import { useState } from "react";
import { useEffect, useRef } from "react";
import Pagination from "../Util/Pagination";
import WaveLoading from "../Util/WaveLoading";
import Button from "../Util/Button";
import GroupInput from "../Groups/GroupInput";
import Popup from "./Popup";
import { isMobile } from "react-device-detect";

export default function Data(props) {
  const jwt = require("jsonwebtoken");
  const [data, setData] = useState(null);
  const [head, setHead] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState(0);
  const [isloading, setIsLoading] = useState(false);
  const [showing, setShowing] = useState(null);
  const [toggleAddMember, setToggleAddMember] = useState(false);
  const [selected, setSelected] = useState(null);
  const [editing, setEditing] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [value, setValue] = useState(null);
  const [error, setError] = useState(null);

  // const token = localStorage.getItem("gdfhgfhtkn");
  // var decoded = jwt.decode(token);
  // const level = decoded.Level.replace(" ", "");
  // const levelname = decoded.LevelName;

  useEffect(() => {
    setIsLoading(true);
    let url = value
      ? `${props.url}/name/${value}/${(currentPage - 1) * 12}`
      : `${props.url}/${(currentPage - 1) * 12}`;
    fetch(url)
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        setIsLoading(false);
        if (data?.data?.length > 0) {
          const cols = Object.keys(data.data[0]);
          let d = [];
          cols.forEach((item) => {
            if (
              item.toLowerCase() !== "nationalid" &&
              item.toLowerCase() !== "user" &&
              item.toLowerCase() !== "createdat" &&
              item.toLowerCase() !== "updatedat" &&
              item.toLowerCase() !== "farmingtype" &&
              item.toLowerCase() !== "id"
            ) {
              d.push(item);
            }
          });
          if (isMobile) {
            setCount(2);
          } else {
            let c = d.length > 5 ? 5 : d.length;
            setCount(c);
          }
          setHead(d);
          setData(data);
        }
      })
      .catch((e) => {
        setIsLoading(false);
      });
  }, [currentPage, refresh]);

  return (
    <div className="data">
      <div className="list">
        <div className="dtitle">
          <h3>{props.title}</h3>
          <div className="search">
            {showSearch && (
              <input
                // className="query"
                onChange={(e) => {
                  setError("");
                  setValue(e.target.value);
                }}
                placeholder="Search by Name"
                type="text"
              />
            )}
            <i
              className="fa fa-search "
              onClick={() => {
                value ? setRefresh(!refresh) : setShowSearch(!showSearch);
              }}
            ></i>
          </div>
        </div>
        <hr />
        <div
          style={{
            gridTemplateColumns: `4em repeat(${head ? count : 0},1fr)`,
          }}
          className="head"
        >
          <h4>SN</h4>
          {head &&
            head.length > 0 &&
            head.map((item, i) => {
              if (i < count) {
                return <h4 key={i}>{item}</h4>;
              }
            })}
        </div>
        {data &&
          data?.data?.length > 0 &&
          data?.data?.map((item, i) => {
            return (
              <Item
                setShowing={setShowing}
                key={i}
                index={i}
                data={item}
                head={head}
                count={count}
                page={currentPage}
                offset={currentPage}
              />
            );
          })}

        <div className="btns">
          {props.title.includes("Members") && (
            <i
              className="fa fa-plus newMember"
              title="Add new member"
              onClick={() => {
                setToggleAddMember(!toggleAddMember);
                setShowing(null);
              }}
            ></i>
          )}

          {data?.total && (
            <Pagination
              totalItems={data?.total}
              onPageChange={(v) => {
                setCurrentPage(v);
              }}
              currentPage={currentPage}
            />
          )}
        </div>
        {showing !== null && (
          <Popup
            showing={showing}
            editing={editing}
            setEditing={setEditing}
            setShowing={setShowing}
            data={data?.data}
            url={props.url}
          />
        )}
        {toggleAddMember && (
          <AddMember
            showing={showing}
            editing={editing}
            setEditing={setEditing}
            setToggleAddMember={setToggleAddMember}
            data={data?.data}
            url={props.url}
            gname={props.gname}
            gtype={props.gtype}
            setSelected={setSelected}
            selected={selected}
          />
        )}
      </div>
      {isloading && <WaveLoading />}
    </div>
  );
}

const AddMember = (props) => {
  const [searchFarmers, setsearchFarmers] = useState(null);
  const [success, setSuccess] = useState(null);
  const [isError, setIsError] = useState(null);

  const [body, setBody] = useState(null);

  useEffect(() => {
    props.selected &&
      setBody({
        FarmerID: props.selected.NationalID,
        Name: props.gname,
        Type: props.gtype,
      });
  }, [props.selected]);

  const List = (params) => {
    return (
      <div
        onClick={() => {
          props.setSelected(params.item);
        }}
        className="s_list"
      >
        <p>
          {params.item.Name} - {params.item.Phone}
        </p>
      </div>
    );
  };

  function searchFarmer(gname, q) {
    fetch(`/api/farmerdetails/seachbynid/${q}`)
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else throw Error();
      })
      .then((data) => {
        setsearchFarmers(data);
      })
      .catch((e) => {});
  }

  function addFarmerToGroup() {
    fetch(`/api/farmergroups`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else throw Error();
      })
      .then((data) => {
        setSuccess(data.success);
        setIsError(data.error);
      })
      .catch((e) => {});
  }

  return (
    <div data-aos="fade-left" className="popup">
      <div className="container">
        <div className="dets">
          <h3>{props?.data[props.showing]?.Name}</h3>
          <i
            onClick={() => {
              props.setToggleAddMember(false);
              props.setSelected(null);
            }}
            className="fa fa-times"
          ></i>
        </div>

        {props.selected ? (
          <div className="confirm">
            {success && <p className="success">{success}</p>}
            {isError && <p className="isError">{isError}</p>}
            <p>
              Confirm to add {props.selected.Name} to{" "}
              {props.gname.replaceAll("%20", " ")}?
            </p>
            <i
              className="newMember"
              onClick={() => {
                if (success || isError) {
                  props.setToggleAddMember(false);
                  setSuccess(null);
                  setIsError(null);
                } else {
                  addFarmerToGroup();
                }
              }}
            >
              Okay
            </i>
          </div>
        ) : (
          <div className="search">
            <p>Add a member to {props.gname.replaceAll("%20", " ")}</p>
            <GroupInput
              type="number"
              handleChange={(e) => {
                searchFarmer(props.gname, e);
              }}
              label="Search ID Number"
              hint="Type to search"
            />
            <div className="search_list">
              {searchFarmers?.map((item, i) => {
                return (
                  <List key={i} item={item} setSelected={props.setSelected} />
                );
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const Item = (props) => {
  const cl = props.index % 2 == 0 ? "white" : "#60606010";

  return (
    <div
      style={{
        gridTemplateColumns: `4em repeat(${props.head ? props.count : 0}, 1fr)`,
        backgroundColor: cl,
      }}
      className="row"
      onClick={() => {
        props.setShowing(props.index);
      }}
    >
      <p>{(props.page-1) * 12 + props.index + 1}</p>
      {props.head.map((item, i) => {
        if (i < props.count) { /*Capitalize each word*/
          return <p key={i}>{props.data[item].replace(/\b\w/g, match => match.toUpperCase())}</p>;
        }
      })}
    </div>
  );
};