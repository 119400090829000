import { useEffect, useRef, useState } from "react";
import ReactDOMServer from "react-dom/server";
import WaveLoading from "../Util/WaveLoading";
import $ from "jquery";

import Map from "ol/Map";
import View from "ol/View";
import TileLayer from "ol/layer/Tile";
import VectorLayer from "ol/layer/Vector";
import GeoJSON from "ol/format/GeoJSON";
import VectorSource from "ol/source/Vector";
import { Circle as CircleStyle, Fill, Stroke, Style } from "ol/style";
import { Text } from "ol/style";
import XYZ from "ol/source/XYZ";
import Overlay from "ol/Overlay";
import {
  ScaleLine,
  ZoomToExtent,
  defaults as defaultControls,
} from "ol/control";
import Graticule from "ol/layer/Graticule";
import Feature from "ol/Feature";
import { Point } from "ol/geom";
import WebGLPointsLayer from "ol/layer/WebGLPoints";

export default function Popup(props) {
    const [isLoading, setIsLoading] = useState(null);
    const [cols, setCols] = useState(null);
    const [entries, setEntries] = useState(null);
    const [cls, setCls] = useState(null);
    const [data, setData] = useState(null);
    const [index, setIndex] = useState(0);
    const [active, setActive] = useState("Basic");
  
    const [subcounty, setSubCounty] = useState(
      new VectorLayer({ title: "Sub Counties" })
    );
    const [ward, setWard] = useState(new VectorLayer({ title: "Wards" }));
    const [basemap, setBasemap] = useState(new TileLayer({ title: "Basemap" }));
    const [graticule, setGraticule] = useState(
      new Graticule({
        strokeStyle: new Stroke({
          color: "rgba(0,0,0,0.5)",
          width: 2,
          lineDash: [0.5, 8],
        }),
        showLabels: true,
        wrapX: false,
        title: "Grid",
      })
    );
    const [map, setMap] = useState(null);
    const mapElement = useRef();
    mapElement.current = map;

    useEffect(async () => {
      if (map) {
        initializeData();
      }
    }, [map]);
  
    function initializeData() {
      LoadData("NyamiraSubcounties", subcounty, styleSC);
      LoadData("NyamiraWards", ward, styleWRD);
    }
  
    useEffect(() => {
      if (active == "Map") {
        basemap.setSource(
          new XYZ({
            url:
              "https://api.mapbox.com/styles/v1/mapbox/satellite-v9/tiles/{z}/{x}/{y}" +
              "?access_token=pk.eyJ1IjoiZ2F0aG9nbzEiLCJhIjoiY2t0djhndnB4MGkzdDJucDg2bW5uNXNrcyJ9.mnbTMXxDrdYnTrb8Gr7_MA",
            crossOrigin: "anonymous",
          })
        );
        const initialMap = new Map({
          target: mapElement.current,
          layers: [basemap, subcounty, ward, graticule],
          view: new View({
            projection: "EPSG:4326",
            center: [36.45, -0.1],
            zoom: 12,
            maxZoom: 20,
          }),
          controls: defaultControls().extend([
            new ZoomToExtent({
              extent: [34.36168, 0.41839, 35.06887, 1.14702],
            }),
            new ScaleLine({
              units: "metric",
              bar: false,
              text: "Scale",
            }),
          ]),
        });
  
        setMap(initialMap);
      }
    }, [active]);
  
    useEffect(() => {
      if (active == "Map" && map) {
        fetch(`/api/farmeraddress/${props.data[props.showing].NationalID}`)
          .then((res) => {
            if (res.ok) return res.json();
            else throw Error("");
          })
          .then((data) => {
  
            let features = new Array(data.length);
            data.map((item, i) => {
              features[i] = new Feature({
                geometry: new Point([
                  parseFloat(item.Longitude),
                  parseFloat(item.Latitude),
                ]),
              });
              const obj = Object.entries(item);
              obj.map((dt) => {
                features[i].values_[dt[0]] = dt[1];
              });
            });
  
            const vs = new VectorSource({
              features: features,
              format: new GeoJSON(),
            });
            const f = new WebGLPointsLayer({
              title: "farmers",
              style: {
                symbol: {
                  symbolType: "circle",
                  size: 20,
                  color: "#FFA500",
                  rotateWithView: false,
                },
                stroke: {
                  color: "#319FD3",
                  width: 1,
                },
              },
              disableHitDetection: false,
              source: vs,
            });
  
            map.addLayer(f);
  
            map.getView().fit(vs.getExtent(), {
              padding: [100, 100, 100, 100],
            });
  
            return () => {
              map.setTarget(null);
            };
          })
          .catch((e) => {});
      }
    }, [active, map]);
  
    useEffect(() => {
      setIsLoading(true);
      setCols(null);
      setCls(null);
      setData(null);
      if (active === "Basic") {
        if (props.showing !== null) {
            const cols = Object.entries(props.data[props.showing])
            let d = [];
            cols.forEach((item) => {
                if (item[0].toLowerCase() !== "geom" && item[0].toLowerCase() !== "id") {
                d.push(item);
                }
            });
            setCols(d);
        }
        setIsLoading(false);
      } else {
        let d = "farmeraddress";
        switch (active) {
          case "Address":
            d = "farmeraddress";
            break;
          case "Map":
            d = "farmeraddress";
            break;
          case "Farm":
            d = "farmerresources";
            break;
          case "Groups":
            d = "farmergroups/farmerid";
            break;
          case "Value Chains":
            d = "farmerdetails/valuechains";
            break;
          default:
            setActive("Basic");
            break;
        }
  
        fetch(`/api/${d}/${props.data[props.showing].NationalID}`)
          .then((res) => {
            if (res.ok) return res.json();
            else throw Error("");
          })
          .then((data) => {
            setIsLoading(false);
            if (data.length > 0) {
              setData(data);
              const cols = Object.entries(data[0]);
              let d = [];
              cols.forEach((item) => {
                if (item[0].toLowerCase() !== "geom" && item[0].toLowerCase() !== "geometry" && item[0].toLowerCase() !== "id") {
                  d.push(item);
                }
              });
              setCls(d);
            //   setCls(d);
            } else if (data) {
            }
          })
          .catch((e) => {
            setIsLoading(false);
          });
      }
    }, [active]);
  
    useEffect(() => {
      if (data) {
        const cols = Object.entries(data[index]);
        let d = [];
        cols.forEach((item) => {
          if (item[0].toLowerCase() !== "geom" && item[0].toLowerCase() !== "geometry" && item[0].toLowerCase() !== "id") {
            d.push(item);
          }
        });
        setCls(d);
      }
    }, [index]);
  
    function getUrl(url) {
      return `/api/geoserver/nyamira/wfs?request=GetFeature&version=1.0.0&typeName=nyamira:${url}&outputFormat=json`;
    }
  
    async function LoadData(url, layer, style) {
      try {
        var response = await $.ajax({
          url: getUrl(url),
          dataType: "json",
          success: {},
          error: function (xhr) {},
        });
        $.when(response).done(function (data) {
          layer.setSource(
            new VectorSource({
              features: new GeoJSON({}).readFeatures(data),
            })
          );
          layer.setStyle(style);
        });
      } catch (error) {}
    }
  
    function styleSC(feature) {
      const style = new Style({
        stroke: new Stroke({
          color: "skyblue",
          width: 7,
        }),
        text: new Text({
          text: feature.values_?.subcounty,
          fill: new Fill({
            color: "lightblue",
            font: "30px sans-serif",
          }),
        }),
      });
  
      return style;
    }
  
    function styleWRD(feature) {
      const style = new Style({
        stroke: new Stroke({
          color: "white",
          width: 2,
        }),
        text: new Text({
          text: feature.values_.ward,
          fill: new Fill({
            color: "white",
            font: "30px sans-serif",
          }),
        }),
      });
  
      return style;
    }
  
    const Bar = (params) => {
      return (
        <p
          onClick={() => {
            setActive(params.txt);
          }}
          className={active === params.txt ? "active" : ""}
        >
          {params.txt}
        </p>
      );
    };

    const Row = (params) => {
        return (
          <div className="prow">
            <p>{params.item[0].toLowerCase() === "createdat" ? "Mapped On" :
                params.item[0].toLowerCase() === "updatedat" ? "Updated On" : params.item[0]}</p>
            <p>{params.item[0].toLowerCase() === "createdat" || params.item[0].toLowerCase() === "updatedat" ? 
                params.item[1].split("T")[0] 
                : params.item[1]}</p>
          </div>
        );
      };
  
    return (
      <div data-aos="fade-left" className="popup">
        <div className="container">
          <div className="dets">
            <h3>{props?.data[props.showing]?.Name.replace(/\b\w/g, match => match.toUpperCase())/*Capitalize each word (names)*/}</h3>
            <i
              onClick={() => {
                props.setShowing(null);
              }}
              className="fa fa-times"
            ></i>
          </div>
          <div className="bar">
            <Bar txt="Basic" />
            <Bar txt="Map" />
            <Bar txt="Address" />
            <Bar txt="Farm" />
            <Bar txt="Groups" />
            <Bar txt="Value Chains" />
          </div>
          {active == "Map" ? (
            <div className="ppmap">
              <div ref={mapElement} className="pmap"></div>
            </div>
          )
          :
          (<div className={cols?.length > 12 || cls?.length > 12 ? "pcontent col2" : "pcontent"}>
            {cols &&
              cols.map((item, i) => {
                return (
                    <Row key={i} item={item} />
                );
              })}
            {cls &&
              cls.map((item, i) => {
                return (
                    <Row key={i} item={item} />
                );
              })}
            {isLoading && <WaveLoading />}
            <div className="tally">
              {data &&
                data.length > 1 &&
                data.map((item, i) => {
                  return (
                    <p
                      className={i === index ? "active" : ""}
                      onClick={() => {
                        setIndex(i);
                      }}
                      key={i}
                    >
                      {i + 1}
                    </p>
                  );
                })}
            </div>
          </div>)}
        </div>
      </div>
    );  
}